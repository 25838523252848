import React from "react";
import { graphql } from "gatsby";
import Layout from "../layouts";
import { RenderComponents } from "../components";

const MainTemplate = (props) => {
  return (
    <Layout location={props.location}>
      <RenderComponents items={props.data.guidesJson.content} />
    </Layout>
  );
};

export default MainTemplate;

export const pageQuery = graphql`
  query ($slug: String!) {
    guidesJson(slug: { eq: $slug }) {
      id
      title
      # type
      content {
        name
        type
        params {
          __typename
          title
          content
          contents
          image
          link
          caption
          list
          table {
            body {
              value
              colSpan
              rowSpan
            }
            header {
              value
              colSpan
              rowSpan
            }
          }
        }
        __typename
      }
    }
  }
`;
